<template>
  <v-container fluid>
    <v-toolbar-title>Perfil do Usuário</v-toolbar-title>
    <v-card class="mt-2 py-6 px-2" elevation="1">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="px-2">
          <v-text-field
            label="Nome"
            v-model="usuario.nome"
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="px-2">
          <v-text-field
            label="Login"
            v-model="usuario.email"
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="px-2">
          <v-text-field
            label="E-mail"
            v-model="usuario.email"
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="px-2">
          <v-btn color="primary" @click="alterarSenha()" block>
            <v-icon left>mdi-lock</v-icon> Alterar Senha
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialogSenha" width="600">
      <v-card>
        <v-card-title class="subtitle-1 primary white--text">
          Alterar Senha
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12" class="px-2">
                <v-text-field
                  label="Senha Antiga"
                  v-model="senha.antiga"
                  outlined
                  dense
                  :rules="regra.antiga"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-text-field
                  label="Nova Senha"
                  v-model="senha.nova"
                  outlined
                  dense
                  :rules="regra.nova"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-text-field
                  label="Confirmar Senha"
                  v-model="senha.confirmar"
                  outlined
                  dense
                  :rules="regra.confirmar"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogSenha = false"> Cancelar </v-btn>
          <v-btn color="primary" @click="salvarSenha()"> Salvar Senha </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="alerta"
      :color="alertaColor"
      :timeout="alertaTimeout"
      top
      style="z-index: 400"
    >
      <v-alert :type="alertaColor">
        {{ alertaTexto }}
      </v-alert>
      <v-btn dark text class="float-right" @click="alerta = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      usuario: {
        nome: null,
        login: null,
        cpf: null,
        email: null,
        cargo: null,
      },
      carregando: false,
      dialogSenha: false,
      senha: {
        antiga: null,
        nova: null,
        confirmar: null,
      },
      regra: {
        antiga: [(v) => !!v || "O campo senha antiga é obrigatório"],
        nova: [
          (v) => !!v || "O campo nova senha é obrigatório",
          (v) =>
            (v && v.length >= 4) || "A senha deve ter no minimo 4 caracteres.",
        ],
        confirmar: [
          (v) => !!v || "O campo confirmar senha é obrigatório",
          (v) => (v && v == this.senha.nova) || "As senhas não conferem.",
        ],
      },
      alerta: false,
      alertaTexto: "",
      alertaColor: "error",
      alertaTimeout: 3000,
    };
  },
  created() {
    this.getUsuario();
  },
  methods: {
    async getUsuario() {
      this.carregando = true;

      this.usuario = this.$store.state.login.user;

     

      //  this.usuario.cpf = this.usuario.cpf ? this.usuario.cpf.replace(/[^\d]/g, "") : ''
      //this.usuario.cpf = ('00000000000' + this.usuario.cpf).slice(-11)
      //        this.usuario.cpf = this.usuario.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")

      this.carregando = false;
    },
    alterarSenha() {
      this.dialogSenha = true;
      this.senha.antiga = null;
      this.senha.nova = null;
      this.senha.confirmar = null;

      try {
        this.$refs.form.resetValidation();
      } catch (error) {
        alert(error)
      }
    },
    async salvarSenha() {
      if (this.$refs.form.validate()) {
        this.carregando = true;

        var response = await axios.post(
          `${this.$SERVER_URL}user/alterasenha`,
          {
            email: this.usuario.email,
            id: this.usuario.id,
            senha: this.senha,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        );

    

        if (response.status == 200) {
          this.alerta = true;
          this.alertaColor = "success";
          this.alertaTimeout = -1;
          this.alertaTexto = response.data.msg;
          setTimeout(() => {
            this.alerta = false;
            this.carregando = false;
            this.dialogSenha = false;
          }, 2000);
        } else {
          this.alerta = true;
          this.alertaColor = "error";
          this.alertaTimeout = -1;
          this.alertaTexto = response.data.msg;

          this.carregando = false;
        }
      }
    },
  },
};
</script>